/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './blog.css';
import moment from 'moment'

interface IBlog { title: string; date: string; content: React.JSX.Element}

const blogItems: IBlog[]  = [
  // {
  //   title: "",
  //   date: "",
  //   content: <></>
  // },
  {
    title: "Added the calculations for the top part of the fork",
    date: "2024-10-11 11:56:00",
    content: <>
    <h1>Top part of fork</h1>
    
    <p>Added the proper rotation to the construction, so the shaft will bounce at the proper angle with a defined width.
      It will have 10° from side to side
    </p>
    
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-10-11/01.mp4" type="video/mp4"></source>
      </video>
    </div>
    </>
  },
  {
    title: "18 Wheel escapement",
    date: "2024-10-10 15:40:00",
    content: <>
    <h1>21600 vph</h1>
    
    <p>After continuing studying NIHS standards, I noticed a vph I was not familiar with... 21600vph
    Is it good? I dont know, but since the 24 wheel is what I need for the 28800 vph, and according to chatgtp it might be a lot, Im trying other options</p>
    The good thing is that I have setups now for 15-18-20-24 teeth escapements.
    <p>Also polishing the shape of the boot a bit more</p>
    
    <div>
      <p>Added a bunch more params to customize the wheel:</p>
      <img src='images/blog/2024-10-10/00.jpg' alt='sketch' />
      <p>18 tooth with the pallet forks in their angle</p>
      <img src='images/blog/2024-10-10/01.jpg' alt='sketch' />
      <p style={{marginTop: 32}}>Whole wheel with the stones</p>
      <img src='images/blog/2024-10-10/02.jpg' alt='sketch' />
    </div>
    </>
  },
  {
    title: "Continuing escapements",
    date: "2024-10-07 06:48:00",
    content: <>
    <h1>Escapement Wheel Continuing</h1>
    
    <p>Ok I could only take George Daniels's escapement so far with the 15+ teeth.</p>
    <p>Had to start modifying it, angles, symetry and more, to make a working escapement.</p>
    <p>I think I still need to improve some angles, heres a very quick animation</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-10-07/01.mp4" type="video/mp4"></source>
      </video>
    </div>
    </>
  },
  {
    title: "Escapement wheels!",
    date: "2024-10-05 06:48:00",
    content: <>
    <h1>Escapement Wheel</h1>
    
    <p>been making a script that follows George Daniel's Watchmaking, 
      particularly the "Semi Equidistant Club-Toothed escapement" 
      (which I think is a good option?), and it works great for 15 teeth escapements, 
      but, for a movement Im working on, I need 24 teeth, 
      Im mostly having trouble calculating the Locking face angle and the position of the left jewel.</p>
    
    <div>
      <h3>15 teeth escapement</h3>
      <img src='images/blog/2024-10-05/01.jpg' alt='sketch' />
      <h2 style={{marginTop: 32}}>20 teeth escapement</h2>
      <p>Im having trouble with the lock face angle</p>
      <img src='images/blog/2024-10-05/02.jpg' alt='sketch' />
      <h2 style={{marginTop: 32}}>24 teeth escapement</h2>
      <img src='images/blog/2024-10-05/03.jpg' alt='sketch' />
    </div>
    </>
  },
  {
    title: "Train of wheels in fusion, rocks!",
    date: "2024-09-29 10:32:00",
    content: <>
    
    <p>New gears in the movement! woho!</p>
    <p>I had to change the fourth wheel though, as the escapment didnt fit... oops</p>
    <div className='code'>
      <div>Main Barrel m=0.1298 z2=112 z1=16 ratio: 7:1 a=8.3072</div>
      <div>Center Wheel m=0.104 z2=80 z1=8 ratio: 10:1 a=4.576</div>
      <div>Third Wheel m=0.104 z2=60 z1=8 ratio: 7.5:1 a=3.536</div>
      <div>Fourth Wheel m=0.1 z2=60 z1=7 8.57:1 a=3.35</div>
    </div>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-30/01.mp4" type="video/mp4"></source>
      </video>
    </div>
    </>
  },
  {
    title: "Train of wheels in fusion, rocks!",
    date: "2024-09-29 15:32:00",
    content: <>
    <h1>🤯</h1>
    <p>Wow fusion is quite powerful,</p>
    <p>Here Im learning the Joint function, </p>
    <p>This will allow me to position the train of wheels exactly as I will like it, keeping the circle dedendum of each gear in place for the gears</p>
    <div className='code'>
       <div>Main Barrel:</div>
       <div>m=0.1298, Z=112, z=16</div>
       <div>Center Wheel:</div>
       <div>m=0.104, Z=80, z=8</div>
       <div>Third Wheel:</div>
       <div>m=0.104, Z=60, z=8</div>
       <div>Fourth Wheel:</div>
       <div>m=0.065, Z=60, z=7</div>
    </div>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-29/01.mov" type="video/mp4"></source>
      </video>
    </div>
    </>
  },
  {
    title: "NIHS-2030: Wheel and Pinion",
    date: "2024-09-28 22:00:00",
    content: <>
    <h3>NIHS-2030: Wheel and Pinion</h3>
    <p>Made this script, that creates a wheel and pinion using NIHS 20-30 standards</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-28/01.mov" type="video/mp4"></source>
      </video>
    </div>
    </>
  },
  {
    title: "Which gear standards are there?",
    date: "2024-09-25 08:44:00",
    content: <>
    <h3>NIHS 20-01 NIHS 20-02 NIHS 20-10</h3>
    <p>Recommended in the book "Wheel & Pinion Cutting in Horology"</p>
    <p>This look amazing, but apparently there's NIHS 20-30 now, which is the latest, just purchased it, but I dont think I can open source that one</p>
    <p>As is, it works and follows the rules, but will move to 20-30 and make it awesome.</p>
    <h4 style={{display: 'flex', alignItems: 'center'}}>
      <span style={{fontSize: 32}}><i className={"fa-brands fa-github"}></i></span>
      <a href="https://github.com/fzuleta/fusion-360-cycloidal-gears-nihs" target='_blank'>See github project</a>
    </h4>
    <img src='images/blog/2024-09-25/01.jpg' alt='sketch' />
    <img src='images/blog/2024-09-25/02.jpg' alt='sketch' />
    </>
  },
  {
    title: "Exploring Gears: British Standard 978 part 2",
    date: "2024-09-23 08:44:00",
    content: <>
    <p>Recommended in the books "Watchmaking" by George Daniels, and "Wheel & Pinion Cutting in Horology"</p>
    <p>These are a nice set, but it seems NIHS is also an option, will explore</p>
    <h4 style={{display: 'flex', alignItems: 'center'}}>
      <span style={{fontSize: 32}}><i className={"fa-brands fa-github"}></i></span>
      <a href="https://github.com/fzuleta/fusion-360-cycloidal-gears-BS-978-part-2" target='_blank'>See github project</a>
    </h4>
    <img src='images/blog/2024-09-23/01.jpg' alt='sketch' />
    </>
  },
  {
    title: "Exploring making a movement",
    date: "2024-09-22 09:57:00",
    content: <>
    <p>Been exploring understanding how a movement is deeply made, realized a whole bunch of things, like... my understanding of gears is wrong</p>
    <p>I will need to re-make all these gears</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-22/03.mov" type="video/mp4"></source>
      </video>
    </div>
    <p>A Balance staff</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-22/02.mov" type="video/mp4"></source>
      </video>
    </div>
    <p>A Barrel arbor (you can see it installed in the first video)</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-22/01.mov" type="video/mp4"></source>
      </video>
    </div>
    </>
  },
  {
    title: "Shrinking a bit the border",
    date: "2024-09-07 11:15:00",
    content: <>
    <p>Shrinking a bit strap metal border, so the watch feels a bit more round, I like it a bit more.</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-07/01.mp4" type="video/mp4"></source>
      </video>
    </div>
    </>
  },
  {
    title: "Strap metal border to hide holes",
    date: "2024-09-06 15:51:00",
    content: <>
    <p>Working on the strap metal border.</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-06/01.mp4" type="video/mp4"></source>
      </video>
    </div>
    </>
  },
  {
    title: "Strap holder thingy",
    date: "2024-09-05 15:08:00",
    content: <>
    <p>Working on the strap holder thingy, still missing the inside spring,</p>
    <p>Also added a little bit of a chamfer to the case, not sure if this is ok for the cnc yet.</p>
    <div>
      <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-05/01.mp4" type="video/mp4"></source>
      </video>
    </div>
    <div>
    <img src='images/blog/2024-09-05/01.jpg' alt='sketch' />
    <img src='images/blog/2024-09-05/02.jpg' alt='sketch' />
    </div>
    </>
  },
  {
    title: "Hands",
    date: "2024-09-04 15:08:00",
    content: <>
    <p>Playing around with the Dial and hands, Im leaving a whole in the center to add the super-luminova.</p>
    <p>Tricky measurements with the micrometric extrudes, it's time to start thinking about the movement.</p>
    <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
      <source src="images/blog/2024-09-04/01.mp4" type="video/mp4"></source>
    </video>
    </>
  },
  {
    title: "Learning Fusion 360",
    date: "2024-09-03 15:08:00",
    content: <>
    <p>Total newb here, imported the svg into fusion 360, started playing around.</p>
    <p>Waiting for the NSCNC Elara, Mira and Lathe to start making some tests!</p>
    <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={true} muted={true}>
      <source src="images/blog/2024-09-03/reloj.mp4" type="video/mp4"></source>
    </video>
    </>
  },
  {
    title: "Paper test",
    date: "2024-08-27 06:00:00",
    content: <>
    <p>Paper tests!.</p>
    <video disablePictureInPicture playsInline controls autoPlay={false} width={'100%'} height={'auto'} loop={false} muted={false}>
      <source src="images/blog/2024-08-27/IMG_3732.MOV" type="video/mp4"></source>
    </video>
    </>
  },
  {
    title: "Study Time",
    date: "2024-08-26 06:00:00",
    content: <>
    <p>Gonna need to level up.</p>
    <img src='images/blog/2024-08-26/01.jpg' alt='sketch' />
    </>
  },
  {
    title: "Second Sketches",
    date: "2024-08-25 10:40:00",
    content: <>
    <p>The adventure continues in Adobe Illustrator.</p>
    <p>Here are some sketch followups (yeah It looks aweful, but let's keep on going, I can see a promise in the proportions and future):</p>
    <p>Im thinking, maybe have a seethrough dial, leather strap, and funky looking hands.</p>
    <img src='images/blog/2024-08-25/01.jpg' alt='sketch' />
    </>
  },
  {
    title: "Total Newb",
    date: "2024-08-24 14:40:00",
    content: <>
    <p>The adventure begins in a sketching software Im confortable with, Adobe Illustrator.</p>
    <p>As I know nothing about manufacturing, I'll take it easier on myself and do a watch without any complications</p>
    <p>Here are some initial sketches:</p>
    <img src='images/blog/2024-08-24/01.jpg' alt='sketch' />
    </>
  },
];

export const Blog = () => {
  const [all, setAll] = React.useState<IBlog[]>([...blogItems]);
  const [list, setList] = React.useState<IBlog[]>([]);
  const more = () => {
    const l = [...list];
    const a = [...all];
    const it = a.shift()
    if (!it) { return; }
    l.push(it);
    setList(l);
    setAll(a);
  }
  React.useEffect(() => {
    const l = [];
    const a = [...all];
    for (let i = 0; i<20; i++) {
      const it = a.shift()
      if (!it) continue;
      l.push(it);
    }
    console.log(`hello`, l)
    setList(l)
    setAll(a);
  }, []);
  
  return <section className='blog'>
  {list.map(it => <div key={`it-${it.date}-${it.title}`}>
  <BlogItem {...it}/>
  </div>)}

  <div className='controller'>
    {/* <a 
      href={`http://`} 
      onClick={(e) => {e.preventDefault(); previousPage()}}
      style={{marginRight: 8}}
      >Previous</a> */}
    <a 
      href={`http://`} 
      onClick={(e) => {e.preventDefault(); more()}}
      style={{marginLeft: 8}}
      >Load next...</a>
  </div>
  </section>
}
const BlogItem = (props: IBlog ) => {
  return <div className='content'>
    <div className='top'>
      <h3>{props.title}</h3>
      <span>{moment(props.date).format('YYYY/MM/DD HH:mm')}</span>
    </div>
    <div className='body'>
      { props.content }
    </div>
  </div>
}